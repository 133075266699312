import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VList,_vm._l((_vm.escuelas),function(escuela,index){return _c(VListItem,{key:`escuela-${index}`},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-school")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('router-link',{staticClass:"link",attrs:{"to":`escuela/${escuela._id}`}},[_vm._v(_vm._s(escuela.nombre))])],1),_c(VListItemSubtitle,[_vm._v(_vm._s(escuela.bucket))])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }