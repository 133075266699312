import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dense":"","flat":"","dark":"","loading":_vm.loading}},[_vm._v("Escuelas "),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.agregarEscuela = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-playlist-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar escuela")])])],1),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"secondary"}}):_vm._e(),_c(VContainer,[_c('listadoEscuelasComponent',{attrs:{"escuelas":_vm.escuelas}})],1)],1),(_vm.agregarEscuela)?_c('agregarEscuelaComponent',{attrs:{"mostrar":_vm.agregarEscuela},on:{"cancelar":function($event){_vm.agregarEscuela = false},"escuelaAgregada":_vm.escuelaAgregada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }