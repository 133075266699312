import { getServerRequest, putServerRequest, postServerRequest, patchServerRequest, deleteServerRequest } from '@/components/globals/services/serverRequest.service';

import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export const getListadoEscuelas = async () => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuelas`;
    return await getServerRequest(url, config);
}

export const getDatosEscuela = async (idEscuela) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/datos/${idEscuela}`;
    return await getServerRequest(url, config);
}

export const agregarModeloEducativo = async (idEscuela, idModelo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/modelo/${idEscuela}`;
    return await putServerRequest(url, { idModelo }, config);
}

export const getAdministradoresEscuela = async (idEscuela) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/admins/${idEscuela}`;
    return await getServerRequest(url, config);
}

export const modificarActivoUsuarioService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const action = data.activo ? 'activar' : 'desactivar';
    const url = `${httpURL}/escuela/admin/${action}/${data.idEscuela}/${data.idUsuario}`;
    return await patchServerRequest(url, { activo: data.activo }, config);
}

export const agregarEscuelaService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela`;
    return await putServerRequest(url, data, config);
}

export const activarEscuelaService = async (idEscuela) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/activar/${idEscuela}`;
    return await patchServerRequest(url, {}, config);
}

export const modificarProfesoresService = async ({ idEscuela, profesoresPermitidos }) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/profesoresPermitidos/${idEscuela}`;
    return await patchServerRequest(url, { profesoresPermitidos }, config);
}

export const modificarAlumnosService = async ({ idEscuela, alumnosPermitidos }) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/alumnosPermitidos/${idEscuela}`;
    return await patchServerRequest(url, { alumnosPermitidos }, config);
}

export const agregarUsuarioService = async (idEscuela, idUsuario) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/administrador/${idEscuela}/${idUsuario}`;
    return await putServerRequest(url, {}, config);
}

export const quitarAdministradorService = async (idUsuarioEscuela) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/administrador/${idUsuarioEscuela}`;
    return await deleteServerRequest(url, config);
}

export const modificarConfiguracionService = async (idEscuela, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/configuracion/${idEscuela}`;
    return await patchServerRequest(url, data, config);
}

export const getConfiguracionService = async (idEscuela) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/escuela/configuracion/${idEscuela}`;
    return await getServerRequest(url, config);
}