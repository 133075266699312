<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dense
        flat
        dark
        class="text-h6"
        :loading="loading"
        >Escuelas
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="agregarEscuela = true">
              <v-icon>mdi-playlist-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar escuela</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-container>
        <listadoEscuelasComponent :escuelas="escuelas" />
      </v-container>
    </v-card>
    <agregarEscuelaComponent
      v-if="agregarEscuela"
      :mostrar="agregarEscuela"
      @cancelar="agregarEscuela = false"
      @escuelaAgregada="escuelaAgregada"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { getListadoEscuelas } from "./escuelas.service";

import agregarEscuelaComponent from "./agregarEscuela.vue";
import listadoEscuelasComponent from "./listadoEscuelas.vue";

export default {
  name: "ListadoEscuelas",
  components: {
    listadoEscuelasComponent,
    agregarEscuelaComponent,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  data() {
    return {
      loading: false,
      agregarEscuela: false,
      escuelas: [],
    };
  },
  watch: {
    sessionToken(value) {
      if (value) this.cargarEscuelas();
    },
  },
  async mounted() {
    if (this.sessionToken) this.cargarEscuelas();
  },
  methods: {
    async cargarEscuelas() {
      try {
        this.loading = true;
        const serverResponse = await getListadoEscuelas();
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else {
          this.escuelas = serverResponse.escuelas;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    escuelaAgregada(escuela) {
      this.escuelas.push(escuela);
      this.agregarEscuela = false;
    }
  },
};
</script>
