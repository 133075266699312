<template>
  <v-card outlined>
    <v-list>
      <v-list-item
        v-for="(escuela, index) in escuelas"
        :key="`escuela-${index}`"
      >
        <v-list-item-icon>
          <v-icon color="primary">mdi-school</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link class="link" :to="`escuela/${escuela._id}`">{{
              escuela.nombre
            }}</router-link>
          </v-list-item-title>
          <v-list-item-subtitle>{{ escuela.bucket }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["escuelas"],
  computed: {
    ...mapGetters(["thumbsURL"]),
  },
  data: () => ({
    loading: false,
  }),
};
</script>
