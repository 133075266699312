import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_vm._v("Agregar escuela")]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VCardText,[_c(VTextField,{attrs:{"label":"Nombre:","outlined":"","dense":"","error-messages":_vm.nombreErrors},on:{"input":function($event){return _vm.$v.nombre.$touch()},"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c(VTextField,{attrs:{"label":"Nombre corto","maxlength":"20","outlined":"","dense":"","error-messages":_vm.nombreCortoErrors},on:{"input":function($event){return _vm.$v.nombreCorto.$touch()},"blur":function($event){return _vm.$v.nombreCorto.$touch()}},model:{value:(_vm.nombreCorto),callback:function ($$v) {_vm.nombreCorto=$$v},expression:"nombreCorto"}}),_c(VTextField,{attrs:{"label":"Folder","maxlength":"20","outlined":"","dense":"","error-messages":_vm.folderErrors},on:{"input":function($event){return _vm.$v.folder.$touch()},"blur":function($event){return _vm.$v.folder.$touch()}},model:{value:(_vm.folder),callback:function ($$v) {_vm.folder=$$v},expression:"folder"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{class:{ 'disable-btn': _vm.disableBtn },attrs:{"color":"primary","small":"","dark":""},on:{"click":function($event){return _vm.agregarEscuela()}}},[_vm._v("Agregar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }